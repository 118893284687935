
export const GoGreenIcon = () => (
    <svg width="94" height="85" viewBox="0 0 94 85" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M88.111 0.444336H5.88881C3.11103 0.444336 0.333252 2.66656 0.333252 5.99989V62.111C0.333252 64.8888 2.55547 67.6666 5.88881 67.6666H88.111C90.8888 67.6666 93.6666 65.4443 93.6666 62.111V5.99989C93.6666 2.66656 90.8888 0.444336 88.111 0.444336ZM87.5555 61.5555H6.44436V5.99989H88.111V61.5555H87.5555Z" fill="white"/>
        <path d="M49.7777 71.5557H43.6666V78.7779H21.4443V84.889H72.5555V78.7779H49.7777V71.5557Z" fill="white"/>
        <path d="M46.9999 15.9999C48.5341 15.9999 49.7777 14.7562 49.7777 13.2221C49.7777 11.688 48.5341 10.4443 46.9999 10.4443C45.4658 10.4443 44.2222 11.688 44.2222 13.2221C44.2222 14.7562 45.4658 15.9999 46.9999 15.9999Z" fill="white"/>
        <path d="M45.3332 33.222V57.1109H48.111V33.222C49.2221 32.6665 49.7776 31.5553 50.3332 30.4442C50.8888 29.3331 50.8888 28.7776 50.8888 27.6665C50.8888 25.4442 49.7776 22.6664 47.5554 21.5553C46.9999 20.9998 46.4443 20.9998 45.8887 21.5553C43.6665 23.222 42.5554 25.4442 42.5554 27.6665C42.5554 29.8887 43.6665 32.1109 45.3332 33.222Z" fill="white"/>
        <path d="M34.2221 52.1108C35.8888 53.2219 37.5555 53.7775 39.7777 53.7775C40.8888 53.7775 41.9999 53.7775 42.5555 53.2219C43.111 53.2219 43.6666 52.6663 43.6666 52.1108C43.6666 49.333 42.5555 45.9997 39.7777 44.333C37.5555 42.6663 34.2221 42.1108 31.4444 42.6663C30.8888 42.6663 30.3333 43.2219 30.3333 43.7775C30.3333 47.6663 31.9999 50.4441 34.2221 52.1108Z" fill="white"/>
        <path d="M40.8888 41.5555C41.4444 41.5555 42 41.5555 42.5555 41.5555C43.1111 41.5555 43.6666 41 43.6666 40.4444C44.2222 37.6666 43.1111 34.8889 41.4444 33.2222C39.7777 31.5555 37 30.4444 34.2222 31C33.6666 31 33.1111 31.5555 33.1111 32.1111C32.5555 34.8889 33.6666 37.6667 35.3333 39.3333C37 41 38.6666 41.5555 40.8888 41.5555Z" fill="white"/>
        <path d="M53.1111 44.3332C50.8888 45.9999 49.2222 49.3332 49.2222 52.111C49.2222 52.6666 49.7777 53.2221 50.3333 53.2221C51.4444 53.7777 52 53.7777 53.1111 53.7777C55.3333 53.7777 57 53.2221 58.6666 52.111C60.8888 50.4443 62.5555 47.111 62.5555 44.3332C62.5555 43.7777 62 43.2221 61.4444 43.2221C58.6666 42.111 55.3333 42.6666 53.1111 44.3332Z" fill="white"/>
        <path d="M49.2222 40.4444C49.2222 41 49.7777 41.5555 50.3333 41.5555C50.8888 41.5555 51.4444 41.5555 51.9999 41.5555C54.2222 41.5555 56.4444 40.4444 57.5555 39.3333C59.2222 37.6667 60.3333 34.8889 59.7777 32.1111C59.7777 31.5555 59.2222 31 58.6666 31C55.8888 30.4444 53.1111 31.5555 51.4444 33.2222C49.7777 34.8889 49.2222 37.6666 49.2222 40.4444Z" fill="white"/>
    </svg>
);