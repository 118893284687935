import { StyledStep, StyledStepNumber, StyledIconContainer } from "../Stepper.styles";
import { IconSuccessAlert } from '@lmig/lmds-react';
import { StepLabel } from "../steps-data";

export interface StepProps {
    text: StepLabel;
    order: number;
    activeStep?: number;
}

const Step = (props: StepProps) => {

    const {activeStep, order, text} = props;

    const isActive = order === activeStep;
    const isComplete = activeStep && activeStep > props.order;

    return ( 
        <StyledStep $active={isActive}>
            {isComplete ? 
                <StyledIconContainer>
                    <IconSuccessAlert color={"teal"}/>
                </StyledIconContainer>
             :
                <StyledStepNumber $active={isActive}>{order}</StyledStepNumber>
            }
            {text}
        </StyledStep>
    );
};
  
  export default Step;