import {
  Disclaimer, Footer as LMDSFooter
} from '@lmig/lmds-react';
import { useEffect, useState } from 'react';
import { apiGwService } from '../../services/api-gw-service';
import LoadingSpinner from '../../components/loadingSpinner/LoadingSpinner';
import { useAppState } from '../../context/AppContext';
import { useNavigate } from 'react-router-dom';
import IFrameError from './IFrameError';
import StyledIFrameContainer from './components/IFrameContainer';
import Stepper from '../../components/stepper/Stepper';
import { stepLabels } from '../../components/stepper/steps-data';
import StyledIframe from './components/IFrame';
import { PAGES } from '../../utilities/consts';

export const AccountCapture = () => {
  const [srcURL, setsrcURL] = useState("");
  const [userValidated, setUserValidated] = useState(false);
  const [isIframeError, setIsIframeError] = useState(false);
  const { state } = useAppState();
  const { user } = state;
  const navigate = useNavigate();
  const [listenerAdded, setListenerAdded] = useState(false);

  if(!listenerAdded){
    window.addEventListener('onHydraSubmit', (event) => {
      navigate(`/${PAGES.DDE_CONFIRMATION}`);
    });
    setListenerAdded(true);
  }
  
  useEffect(() => {
    if(!userValidated) {
      console.log('--- Call URL API')
      try{
        apiGwService.getUrl.getUrl(user).then(res => {
          if(res){
            setsrcURL(res);
            setUserValidated(true);
          } else {
            setIsIframeError(true);
          }
  
        });
      }
      catch(error){
        setIsIframeError(true);
      }

    }
}, [setsrcURL, userValidated, setUserValidated, setIsIframeError, user, navigate])


  if(!userValidated && !isIframeError)
  {
    return  <LoadingSpinner />
  }
  return (
      <span className="accountcapture">
        <Stepper activeStepLabel={stepLabels.FINANCIAL_INFORMATION}/>
        {isIframeError ? 
          <IFrameError />
        :
        <StyledIFrameContainer>
          Please enter account details below:
          <StyledIframe title="captureFrame" src={srcURL}></StyledIframe>
        </StyledIFrameContainer>
      }
      <LMDSFooter>
        <hr className="horizontal-rule" />
        <Disclaimer>
          <p>© 2023 Liberty Mutual Insurance Company, 175 Berkeley Street, Boston, MA 02116</p>
        </Disclaimer>
      </LMDSFooter>
      </span>
  );
}
