import styled from 'styled-components';
import colorTokens from '@lmig/lmds-tokens/dist/json/core/color.json';

const lmdsColors = colorTokens.lmig.color;
const { green } = lmdsColors;

export const GoGreenContainer = styled.div`
    background-color: ${green.light};
    height: 311px;
    border: 1px solid ${green.dark};
    border-radius: 12px;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 48px;
    margin-top: 48px;
`;

export const GoGreenIconContainer = styled.div`
    background-color: ${green.dark};
    width: 179px;
    height: 179px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const DirectDepositSectionContainer = styled.div`
    padding-bottom: 82px;
    padding-left: 24px;
`;

export const CheckSectionContainer = styled.div`
    padding-bottom: 64px;
`

export const CheckboxContainer = styled.div`
    margin-top: 48px;
    display: flex;
    align-items: center;
`;

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
`;