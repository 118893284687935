import React from 'react';
import { Header as LMDSHeader, LogoLibertyMutualHoriz } from '@lmig/lmds-react';

import './Header.scss';

const Header = () => (
  <LMDSHeader id="lmds-header">
    <LogoLibertyMutualHoriz className="header-logo" />
  </LMDSHeader>
);

export default Header;