import { ConfirmationDisplay } from './confirmationDisplay';
import {Heading} from '@lmig/lmds-react';

interface ConfirmationSuccessProps {
    heading: string;
    bodyText: string;
}

export const ConfirmationSuccess = (props: ConfirmationSuccessProps) => {
    const {heading, bodyText} = props;
    return (
        <>
            <Heading type="h2-bold">{heading}</Heading> 
            <ConfirmationDisplay />
            <p>{bodyText}</p>
        </>
    );
  };
  