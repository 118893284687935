import styled from 'styled-components';
import { ModalFooter, Button } from '@lmig/lmds-react';

export const IconContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 24px;
    margin-bottom: 14px;
`;

export const StyledModalFooter = styled(ModalFooter)`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 16px;
    row-gap: 10px;
    margin-top: 48px;
`

export const StyledButton = styled(Button)`
    width: 341px;
`;