import { AlphanumericInput,DatePicker} from "@lmig/lmds-react";
import { Field } from "formik";

export const  getQuestionComponents = (setFieldValue: (a: string, b: any)=>{}) => {
    return{
      dateOfLoss:(<Field key="dateOfLoss" name="dateOfLoss" component={DatePicker} labelVisual="Date of Loss (MM/DD/YYYY)" required innerRef={{name:"dateOfLoss"}} onChange={(e: { target: { value: any } }) => setFieldValue('dateOfLoss', e)} onKeyUp={ (e: { target: { value: any } }) => setFieldValue('dateOfLoss', e.target.value)} inputProps={{name:"dateOfLoss",key:"dateOfLoss"}} validate={validateDate} />),
      lastFourPhoneDigits: (<Field key="lastFourPhoneDigits" name="lastFourPhoneDigits" as={AlphanumericInput} filter={/^[0-9]*$/} maxLength={4} labelVisual="Last Four Digits Of Phone Number" required />), 
    }
  }

function validateDate(value: string){
  if(value.length === 10){
    return false; 
  }
  return 'Please enter a valid date';
}
