import { Accordion, AccordionTab, Alert, BodyText, Button, Card, Checkbox, Disclaimer, FieldGroup, Form, GridCol, GridRow, Footer as LMDSFooter, Link } from '@lmig/lmds-react';
import { useNavigate } from 'react-router-dom';
import TermData from './TermData.json';
import parse from 'html-react-parser';
import { useState, useEffect } from 'react';
import Stepper from '../../components/stepper/Stepper';
import { stepLabels } from '../../components/stepper/steps-data';
import { ButtonsContainer } from './Terms.styles';
import { useAppStore, PAYMENT_TYPES } from '../../store/store';
import { PAGES } from '../../utilities/consts';

export const Terms = () => {
  const navigate = useNavigate();
  const [termsActive, setTermsActive] = useState(true);
  const [privacyActive, setPrivacyActive] = useState(false);
  const [agreementActive, setAgreementActive] = useState(false);
  const [acceptCheckbox, setAcceptCheckbox] = useState(false);
  const [adviseCheckbox, setAdviseCheckbox] = useState(false);
  const [hideError, setHideError] = useState(true);
  const [hasAgreedTerms, setHasAgreedTerms] = useState(false);
  const [hasAgreedPrivacyPolicy, setHasAgreedPrivacyPolicy] = useState(false);
  const {isCheckEnabled, selectedPaymentType } = useAppStore();
  const {ACCOUNT_CAPTURE, CHECK_CONFIRMATION, SELECT_PAYMENT_TYPE} = PAGES;

  const constructTerm = ({ id, term }: { id: any, term: any }) => {
    const data = parse(term);
    return (
      <li key={id}>
        {data}
      </li>
    );
  };
  const moveToPrivacy = () => {
    setTermsActive(false)
    setPrivacyActive(true)
    setAgreementActive(false)
  };
  const moveToAgreement = () => {
    setTermsActive(false)
    setPrivacyActive(false)
    setAgreementActive(true)
  };
  const moveToTerms = () => {
    setTermsActive(true)
    setPrivacyActive(false)
    setAgreementActive(false)
  };

  const handleOnChangeAcceptTerms = (event: { target: { checked: boolean | ((prevState: boolean) => boolean); }; }) => {
    setHasAgreedTerms(event.target.checked)
    event.target.checked && moveToPrivacy();
  };

  const handleOnChangeAcceptPrivacy = (event: { target: { checked: boolean | ((prevState: boolean) => boolean); }; }) => {
    setHasAgreedPrivacyPolicy(event.target.checked)
    event.target.checked && moveToAgreement();
  };

  const handleOnChangeAccept = (event: { target: { checked: boolean | ((prevState: boolean) => boolean); }; }) => {
    setAcceptCheckbox(event.target.checked)
  };

  const handleOnChangeAdvise = (event: { target: { checked: boolean | ((prevState: boolean) => boolean); }; }) => {
    setAdviseCheckbox(event.target.checked)
  };

  const handleAccept = () => {
    if (adviseCheckbox && acceptCheckbox && hasAgreedTerms && hasAgreedPrivacyPolicy) {
      const nextStepRoute = `/${selectedPaymentType === PAYMENT_TYPES.CHECK ? CHECK_CONFIRMATION : ACCOUNT_CAPTURE}`;
      navigate(nextStepRoute);
    }
    else {
      setHideError(false)
    }
  };

  useEffect(() => {
    if (!hideError && (hasAgreedTerms && hasAgreedPrivacyPolicy && acceptCheckbox && adviseCheckbox)){
      setHideError(true);
    }
  }, [hasAgreedTerms, hasAgreedPrivacyPolicy, acceptCheckbox, adviseCheckbox, hideError])

  return (
    <span className="terms" >
      <Stepper activeStepLabel={stepLabels.AGREEMENTS}/>
      <Accordion as="h4" style={{marginTop: '16px', wordWrap: 'break-word'}}>
        <AccordionTab labelVisual="Terms and Conditions" active={termsActive} onClick={() => moveToTerms()}>
          <Card style={{ marginBottom: '0' }}>
            <GridRow gutters>
              <BodyText type="default" data-testid="termsText">
                <p>
                  Please read the following Terms and Conditions. By clicking
                  {' '}
                  <b>I Accept</b>
                  {' '}
                  you signify you have read, understand,
                  and agree to the Direct Deposit Enrollment Terms and Conditions.
                </p>
              </BodyText>
              <BodyText type="default" data-testid="printText">
                Please print this screen for your records and any future inquiries.
              </BodyText>
              <GridCol base={12} style={{ paddingTop: '16px' }}>
                <BodyText type="default" data-testid="termsContent">
                  <p>
                    All references to Liberty Mutual Insurance include Liberty Mutual Insurance
                    Company and its affiliates, and subsidiaries that are members of the Liberty
                    Mutual Group (collectively referred to as “Liberty Mutual”).
                  </p>
                  <p>
                    By accepting the terms and conditions of enrollment, you agree to the following:
                  </p>
                  <ol>
                    {TermData.map((data) => constructTerm(data))}
                  </ol>
                </BodyText>
              </GridCol>
            </GridRow>
            <GridRow style={{ paddingTop: '40px' }}>
              <GridCol style={{ display: 'flex', justifyContent: 'space-between' }} base={12}>
              <Checkbox
                    name="acceptTerms"
                    labelVisual="I Accept"
                    data-testid="acceptTermsCheckbox"
                    checked={hasAgreedTerms}
                    onChange={handleOnChangeAcceptTerms}
                  />
              </GridCol>
            </GridRow>
          </Card>
        </AccordionTab>
        <AccordionTab labelVisual="Privacy Policy" active={privacyActive} onClick={function onClick() { moveToPrivacy() }}>
          <Card style={{ marginBottom: '0' }}>
            <GridRow gutters>
              <BodyText type="default" data-testid="privacyText">
                <p>
                  Please read our
                  {' '}
                  <Link href="https://www.libertymutualgroup.com/general/about-lm/corporate-information/commercial-lines" target="_blank">
                    Privacy Policy
                  </Link>
                  . By clicking
                  {' '}
                  <b>I Accept</b>
                  {' '}
                  you signify you have read, understand, and agree to the Privacy Policy.
                </p>
              </BodyText>
            </GridRow>
            <GridRow style={{ paddingTop: '40px' }}>
              <GridCol style={{ display: 'flex', justifyContent: 'space-between' }} base={12}>
                <Checkbox
                    name="acceptPrivacy"
                    labelVisual="I Accept"
                    data-testid="acceptPrivacyCheckbox"
                    checked={hasAgreedPrivacyPolicy}
                    onChange={handleOnChangeAcceptPrivacy}
                  />
              </GridCol>
            </GridRow>
          </Card>
        </AccordionTab>
        <AccordionTab labelVisual="Direct Deposit Agreement" active={agreementActive} onClick={function onClick() { moveToAgreement() }}>
          <Card style={{ marginBottom: '0' }}>
            <Form id="agreementForm">
              <GridRow gutters>
                <BodyText type="default" data-testid="agreementText">
                  <p>
                    Please review and accept our agreement statements. Select
                    {' '}
                    <b>Continue</b>
                    {' '}
                    to proceed with enrollment in Direct Deposit.
                  </p>
                </BodyText>
                <FieldGroup
                  id="agreementFieldGroup"
                  disableMargins="top"
                >
                  <Checkbox
                    name="accept"
                    labelVisual="I agree to accept payments via Direct Deposit."
                    data-testid="acceptCheckbox"
                    checked={acceptCheckbox}
                    onChange={handleOnChangeAccept}
                  />
                  <Checkbox
                    name="advise"
                    labelVisual="I agree to advise the Company immediately of any change in circumstances
                            which may affect my entitlement to receive these payments."
                    data-testid="adviseCheckbox"
                    checked={adviseCheckbox}
                    onChange={handleOnChangeAdvise}
                  />                  
                </FieldGroup>
              </GridRow>
            </Form>
          </Card>
        </AccordionTab>
      </Accordion>
      <div hidden={hideError}>
        <Alert highlightType='negative' >
          Please accept all sections before continuing.
        </Alert>
      </div>
      <GridCol base={12} style={{ paddingTop: '16px'}}>
        <ButtonsContainer>
          {isCheckEnabled &&
            <Button
                aria-label='Back'
                onClick={() => { navigate(`/${SELECT_PAYMENT_TYPE}`); }}
              >
                Back
            </Button>
          }
          <Button
            type="submit"
            variant="primary"
            aria-label='Continue'
            onClick={() => { handleAccept() }}
          >
              Continue
          </Button>
        </ButtonsContainer>
      </GridCol>
      <LMDSFooter>
        <hr className="horizontal-rule" />
        <Disclaimer>
          <p>© 2023 Liberty Mutual Insurance Company, 175 Berkeley Street, Boston, MA 02116</p>
        </Disclaimer>
      </LMDSFooter>
    </span>
  );
};
