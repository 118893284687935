import styled from "styled-components";
import breakpointTokens from '@lmig/lmds-tokens/dist/json/core/breakpoint.json';


const StyledIFrameContainer = styled.div`
    min-height: 750px;
    margin-top: 16px;
    @media (max-width: ${breakpointTokens.lmig.breakpoint.md.min}) {
        min-height: 77vh;
    }
`;

export default StyledIFrameContainer;