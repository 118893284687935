import {createTheme} from "@mui/material/styles";

export const theme = createTheme({
    palette: {
        primary: {
            main: '#1a1446',
        },
        secondary:  {
            main: '#78E1E1',
        },
        text: {
            primary: '#1a1446',
        },
    },
    components: {
        MuiListItem: {
            styleOverrides: {
                root: {
                    color: '#1a1446',
                },
            }
        },
        MuiTableCell: {
            styleOverrides: {
                head: {
                    backgroundColor: '#e6e6e6 !important',
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                containedPrimary: {
                    backgroundColor: '#78E1E1',
                    color: '#1A1446',
                    '&:hover': {
                        backgroundColor: '#68D1D1'
                    }
                },
                outlinedSecondary: {
                    color: '#1A1446',
                    backgroundColor: '#FFFFFF',
                    borderColor: '#1A1446',
                    '&:hover': {
                        backgroundColor: '#68D1D1',
                        borderColor: '#1A1446',
                    }
                }
            }
        }
    }
});
