import {
  Card,
  GridCol,
  GridRow,
  Notification,
} from '@lmig/lmds-react';

type ErrorProps = {
  title: string;
  alert: string;
  message: string;
};

export const ErrorComponent: React.FunctionComponent<ErrorProps> = (props) => {
    const { title, alert, message } = props;
  return (
    <span className="errorPage">
      <GridRow className="grid-row" justify="center" style={{ paddingTop: '32px', paddingBottom: '80px' }}>
        <GridCol base={12} className="card-basic" style={{ maxWidth: '632.56px' }}>
          <Card title={title} titleHeadingLevel="h3" style={{ marginBottom: '0' }}>
            <GridRow gutters>
              <GridCol base={12} style={{ paddingTop: '0px' }}>
                <Notification
                  alert={alert}
                  highlightType="negative"
                >
                  {message}
                </Notification>
              </GridCol>
            </GridRow>
            <GridRow style={{ paddingTop: '32px' }}>
              <GridCol style={{ display: 'flex', justifyContent: 'space-between' }} base={12}>
              </GridCol>
            </GridRow>
          </Card>
        </GridCol>
      </GridRow>
    </span>
  );
};
