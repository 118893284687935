import { StyledMobileStepper, StyledStepNumber } from "../Stepper.styles";
import { StepperDisplayProps } from "../Stepper";

const MobileStepper = (props: StepperDisplayProps) => {
    const {activeStepNumber, steps} = props;
    const activeStepIndex = activeStepNumber - 1;

    return( 
        <StyledMobileStepper>
            <StyledStepNumber $active={true}>{activeStepNumber}</StyledStepNumber>
            {`Step ${activeStepNumber} of ${steps.length}: ${steps[activeStepIndex].text}`}
        </StyledMobileStepper>
    );
}
  
  export default MobileStepper;