import { apiGwService } from '../../services/api-gw-service';
import LoadingSpinner from '../../components/loadingSpinner/LoadingSpinner';
import { useEffect, useState } from 'react';
import { useAppState } from '../../context/AppContext';
import { ConfirmationSuccess } from '../../components/confirmation/ConfirmationSuccess';
import { ErrorComponent } from '../../components/error/ErrorComponent';
import { STRINGS } from '../../utilities/strings';

export const CheckConfirmation = () => {
    const { state } = useAppState();
    const { user } = state;
    const [updateAccountCallFinished, setUpdateAccountCallFinished] = useState(false);
    const [updateAccountError, setUpdateAccountError] = useState(false);
    const { 
        CHECK_CONFIRMATION_HEADING, 
        CHECK_CONFIRMATION_BODY, 
        CHECK_REQUEST_FAILED_ALERT, 
        CHECK_REQUEST_FAILED_MESSAGE, 
        GENERIC_PAYMENT_ERROR, 
        CONTACT_REP,
    } = STRINGS;

  useEffect(() => {
    if(!updateAccountCallFinished){ 
        apiGwService.updateAccountStatus.updateAccountStatus('DDE_REJECTED', user?.id || '').then(response => {
            if(!response.accountUpdatedSuccessfully || response.errorOccured){
                setUpdateAccountError(true);
              }
            setUpdateAccountCallFinished(true);
        });
    }

  }, [updateAccountCallFinished, user?.id]);

    if(!updateAccountCallFinished){
        return(
            <LoadingSpinner />
        )
    }

    return(
        <>
        {updateAccountError ?
            <ErrorComponent
                title={GENERIC_PAYMENT_ERROR} 
                alert={CHECK_REQUEST_FAILED_ALERT} 
                message={`${CHECK_REQUEST_FAILED_MESSAGE} \n ${CONTACT_REP} \n `} 
            />
            :
            <ConfirmationSuccess heading={CHECK_CONFIRMATION_HEADING} bodyText={CHECK_CONFIRMATION_BODY}/>
        }
        </>
    )
};