import { Modal, ModalHeader, ModalBody, Heading } from "@lmig/lmds-react";
import { StyledModalFooter, StyledButton, IconContainer } from "./CheckConfirmationModal.styles";
import { PaperCheckIcon } from "../assets/PaperCheckIcon";


interface CheckConfirmationModalProps {
    isOpen: boolean,
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>,
    handleConfirm: () => void;
}

export const CheckConfirmationModal = (props: CheckConfirmationModalProps) => {

    const {isOpen, setIsOpen, handleConfirm} = props;

    return (
        <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
            <ModalHeader>
                <IconContainer>
                    <PaperCheckIcon />
                </IconContainer>
                <Heading type="h3-light">
                    {'Are you sure you want to receive a check in the mail?'}
                </Heading>
            </ModalHeader>
            <ModalBody>
                <Heading type="h4">
                    {'Paper checks will typically take up to 15 business days to arrive.'}
                </Heading>
            </ModalBody>
            <StyledModalFooter>
                <StyledButton onClick={() => setIsOpen(false)}>
                    {'No, let me re-select'}
                </StyledButton>
                <StyledButton variant="primary" onClick={handleConfirm}>
                    {'Yes, mail a check'}
                </StyledButton>
            </StyledModalFooter>
        </Modal>
    );
} 