import { create } from 'zustand';

export const PAYMENT_TYPES = {
    NOT_SELECTED: 'NOT_SELECTED',
    CHECK: 'CHECK',
    DIRECT_DEPOSIT: 'DIRECT_DEPOSIT'
} as const;

type PaymentType = typeof PAYMENT_TYPES[keyof typeof PAYMENT_TYPES];

interface AppStore {
    isCheckEnabled: boolean;
    selectedPaymentType: PaymentType;
    setIsCheckEnabled: (isCheckEnabled: boolean) => void;
    setSelectedPaymentType: (paymentType: PaymentType) => void;
}

export const useAppStore = create<AppStore>()((set) => ({
    isCheckEnabled: false,
    selectedPaymentType: PAYMENT_TYPES.DIRECT_DEPOSIT,
    setIsCheckEnabled: (isCheckEnabled: boolean) => set({isCheckEnabled}),
    setSelectedPaymentType: (paymentType: PaymentType) => set({selectedPaymentType: paymentType}),
}))
